<template>
  <!-- 开放实验 -->
  <div class="mainpage">
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div class="maintext">学期:</div>
          <el-select class="elinput" v-model="form.semester_id" placeholder="请选择" @change="change">
            <el-option v-for="item in optionone" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div style="margin-left: 60px" class="maintext">学院:</div>
          <el-select class="elinput" v-model="form.college_id" placeholder="请选择">
            <el-option v-for="item in optionthree" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <div style="margin-left: 60px" class="maintext">实验室:</div>
          <el-select class="elinput" v-model="form.lab_id" filterable placeholder="请选择">
            <el-option v-for="item in optionfour" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="right">
          <el-button type="primary" @click="searchbt">查询</el-button>
          <el-button type="info" @click="resetbt">重置</el-button>
          <!-- <div class="bt searchbt" @click="searchbt">查询</div>
          <div class="bt restbt" @click="resetbt">重置</div> -->
        </div>
      </div>

      <div class="main-contain">
        <div class="contain">
          <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table" stripe
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#FFFFFF',
            }" :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }">
            <el-table-column prop="college_name" label="学院">
            </el-table-column>
            <el-table-column prop="lab_name" label="实验室"> </el-table-column>
            <el-table-column prop="lab_room_name" label="实验用房">
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
            <el-table-column prop="week_number" label="开放周次">
              <template slot-scope="scope">
                {{ scope.row.week_number }}
              </template>
            </el-table-column>
            <el-table-column prop="lesson_count" label="时数">
            </el-table-column>
            <el-table-column prop="experiment_name" label="项目名称">
            </el-table-column>
            <el-table-column prop="teacher_name" label="指导老师">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="operatcontain">
                  <el-button class="opreatbt" @click="opreatbt(scope.row)">预约</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10" :current-page="form.page"
          @current-change="changePage">
        </el-pagination>
      </div>
    </div>
    <Customdialog ref="customdialog" class="customdialog" width="60%" type="table" title="开放实验预约" :showclose="true">
      <div slot="dialogbody">
        <Table :closebt="closedialog" ref="mytable"></Table>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import Table from "./table";
import { getToken, getIdentifierToken } from "@/utils/auth";
import { getlablist } from "@/api/lab";
import { gettreelist } from "@/api/common/semester";
import { getcollegelist } from "@/api/college";
import { getopenexperimentlist } from "@/api/openexperiment";
import Customdialog from "@/components/customdialog.vue";
export default {
  name: "mainpage",
  components: {
    Customdialog,
    Table,
  },
  data() {
    return {
      token: getToken() ? true : false,
      total: 0,
      form: {
        semester_id: "",
        college_id: "",
        lab_id: "",
        page: 1,
        per_page: 10
      },
      optionone: [],
      optionthree: [],
      optionfour: [],
      tableData: [],
      week_numbers: ''
    };
  },
  computed: {},
  created() {
    this.gettreelist();
    this.getcollegelist();
    this.getlablist();
  },
  methods: {
    closedialog() {
      this.$refs.customdialog.dialogclosebt();
      // location.reload()
    },
    todetail() {
      this.$router.push({
        path: "/openexperiment/experimentdetails",
      });
    },
    opreatbt(item) {
      if (this.token) {
        if (getIdentifierToken() == 3) {
          this.$confirm("该功能仅对校内人员开放!", "提示", {
            confirmButtonText: "知道了",
            showCancelButton: false,
            type: "warning",
          })
            .then(() => { })
            .catch(() => { });
        } else if (getIdentifierToken() == 4) {
          this.$confirm("该功能未对您开放,请联系管理员!", "提示", {
            confirmButtonText: "知道了",
            showCancelButton: false,
            type: "warning",
          })
            .then(() => { })
            .catch(() => { });
        } else {
          if (item.experiment_type == 0) {
            this.$refs.customdialog.dialogopenbt();
            this.$nextTick(() => {
              this.$refs.mytable.inidata(item, this.week_numbers, 0);
              
            });
          } else if (item.experiment_type == 1 || item.experiment_type == 2) {
            this.$refs.customdialog.dialogopenbt();
            this.$nextTick(() => {
              this.$refs.mytable.inidata(item, this.week_numbers, 1);
            });
          }
        }
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {

          });
      }
    },
    searchbt() {
      this.form.page = 1
      this.getopenexperimentlist();
    },
    changePage(val) {
      this.form.page = val
      this.getopenexperimentlist();
    },
    change(data){
       this.form.semester_id = data
       this.week_numbers = this.optionone.find(el=>el.value==data).week_number
    },
    resetbt() {
      this.form.college_id = "";
      this.form.lab_id = "";
      this.getopenexperimentlist();
    },
    getopenexperimentlist() {
      getopenexperimentlist(this.form)
        .then((response) => {
          this.tableData = response.data.data;
          this.total = response.data.total;
        })
        .catch((error) => { });
    },

    gettreelist() {
      const params = {
        page: 1,
        per_page: 100
      }
      gettreelist(params)
        .then((response) => {
          if (response.code === 0) {
            this.optionone = response.data.data.map(item => {
              return {
                label: item.start_year + '-' + item.end_year + '/' + (item.nper == 1 ? '第一学期' : '第二学期'),
                value: item.id,
                is_current: item.is_current,
                week_number: item.week_number
              }
            })
            this.optionone.forEach(el => {
              if (el.is_current == 1) {
                this.form.semester_id = el.value
                this.week_numbers = el.week_number
                this.getopenexperimentlist();
              }
            })
          }
        })
        .catch((error) => { });
    },
    getcollegelist() {
      this.optionthree = [];
      const params = {
        record_type: 1
      }
      getcollegelist(params)
        .then((response) => {
          if (response.code === 0) {
            if (response.data.data && response.data.data.length > 0) {
              response.data.data.forEach((item) => {
                this.optionthree.push({
                  value: item.id,
                  label: item.name,
                });
              });
            }
          }
        })
        .catch((error) => { });
    },
    getlablist() {
      var form = {
        page: 1,
        per_page: 10000
      }
      getlablist(form)
        .then((response) => {
          if (response.code === 0) {
            if (response.data.data && response.data.data.length > 0) {
              response.data.data.forEach((item) => {
                this.optionfour.push({
                  value: item.id,
                  label: item.lab_name,
                });
              });
            }
          }
        })
        .catch((error) => { });
    },
  },
};
</script>
<style scoped lang="scss">

.mainpage {
  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    .searchcontain {
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .maintext {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
        }
        .restbt {
          color: #3d84ff;
        }
        .searchbt {
          margin-right: 10px;
          border: 1px solid #3d84ff;

          background: #3d84ff;
          color: white;
        }
      }
    }
    .main-contain {
      height: 100%;
      width: 100%;
      .contain {
        width: 100%;
        height: 100%;
        .Table {
          width: 100%;

          .btgroup {
            .bt {
              border: none;
            }
          }
          .opreatbt {
            border: none;
            background: none;
            margin: 0px;
            padding: 0px;
            color: #3d84ff;
          }
        }
      }
    }
    .elpagination {
      margin: 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
}
.btgroup {
  color: #3d84ff;
  height: 60px;
  width: 100%;
  .bt {
    margin-top: 10px !important;
    width: 100%;
    height: 20px;
    margin: 0px;
    padding: 0px;
    border: none;
  }
}
</style>
